import { memo } from 'react';
import usePlusMembershipFeatures from '../../../../hooks/localeConstants/usePlusMembershipFeatures';
import { BackgroundIcon } from '../../../Icons/Icons';
import {
  BenefitsOfPlusContainer,
  BenefitsOfPlusContainerContent,
  BenefitsOfPlusContainerContentItem,
  BenefitsOfPlusContainerTitle,
  BenefitsOfPlusWrapper,
} from '../LandingPageStyle';

function BenefitsOfPlus() {
  const { features } = usePlusMembershipFeatures();

  return (
    <BenefitsOfPlusWrapper>
      <BenefitsOfPlusContainer>
        <BenefitsOfPlusContainerTitle>
          Benefits of Driffle{' '}
          <BackgroundIcon
            url={`/logo/plus-logo.svg`}
            height="20px"
            width="40px"
            margin="0 0px"
          />
        </BenefitsOfPlusContainerTitle>
        <BenefitsOfPlusContainerContent>
          {features.map((feature, index) => (
            <BenefitsOfPlusContainerContentItem key={index}>
              <div>
                <BackgroundIcon
                  url={`/icons/purple-tick-24.svg`}
                  height="20px"
                  width="20px"
                  margin="0"
                />
              </div>
              {feature}
            </BenefitsOfPlusContainerContentItem>
          ))}
        </BenefitsOfPlusContainerContent>
      </BenefitsOfPlusContainer>
    </BenefitsOfPlusWrapper>
  );
}

export default memo(BenefitsOfPlus);
