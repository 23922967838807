import { memo, useContext } from 'react';
import { useLocale } from '../../../../contexts/LocalizationContext';
import { displayCurrencyValue } from '../../../../helper/helper';
import { useAppSelector } from '../../../../redux/hooks';
import { displayUniformDate } from '../../../../utils/displayDateAndTime';
import { MembershipPageContext } from '../../context/MembershipLandingPageContext';
import {
  AlreadyPlusMemberCardBody,
  AlreadyPlusMemberCardContainer,
  AlreadyPlusMemberCardFooter,
  AlreadyPlusMemberCardTitle,
  AlreadyPlusMemberCardWrapper,
  MembershipStatusTile,
} from '../LandingPageStyle';
import AlreadyPlusMemberCardSkeleton from './AlreadyPlusMemberCardSkeleton';

function AlreadyPlusMemberCard() {
  const user = useAppSelector((state) => state.user);
  const { locationDetails } = useAppSelector((state) => state.global);
  const { messages } = useLocale();
  const {
    plus_membership_next_billing_date_msg,
    plus_you_have_saved_so_msg,
    plus_you_still_need_to_make_msg,
    plus_grab_the_latest_games_and_msg,
    plus_savings_till_now_msg,
    active_msg,
    plus_hello_plus_member_msg,
    plus_membership_ends_on_date_msg,
  } = messages || {};

  const { loading, savingAmount } = useContext(MembershipPageContext);

  return (
    <>
      {loading ? (
        <AlreadyPlusMemberCardSkeleton />
      ) : (
        <>
          <AlreadyPlusMemberCardWrapper>
            <AlreadyPlusMemberCardTitle>
              {savingAmount > 0
                ? plus_savings_till_now_msg
                : plus_hello_plus_member_msg?.replace('[--PLUS--]', 'plus')}
            </AlreadyPlusMemberCardTitle>
            <AlreadyPlusMemberCardContainer>
              {savingAmount > 0 ? (
                <AlreadyPlusMemberCardBody>
                  <h6
                    dangerouslySetInnerHTML={{
                      __html:
                        plus_you_have_saved_so_msg?.replace(
                          '[--PRICE--]',
                          `<span>
                            ${displayCurrencyValue(
                              String(savingAmount),
                              locationDetails?.currency_symbol,
                              locationDetails?.multiplier
                            )}
                          </span>`
                        ) ?? '',
                    }}
                  />

                  <p>{plus_grab_the_latest_games_and_msg}</p>
                </AlreadyPlusMemberCardBody>
              ) : (
                <AlreadyPlusMemberCardBody>
                  <h6
                    dangerouslySetInnerHTML={{
                      __html: `
                      ${
                        plus_you_still_need_to_make_msg?.replace(
                          '[--PLUS--]',
                          `
                            <img
                                src="/logo/plus-logo.svg"
                                alt="plus-icon"
                                width="44px"
                                height="24px"
                                style='display: inline-block;'
                            />
                          `
                        ) ?? ''
                      }
                      `,
                    }}
                  />

                  <p>{plus_grab_the_latest_games_and_msg}</p>
                </AlreadyPlusMemberCardBody>
              )}
              <AlreadyPlusMemberCardFooter>
                <MembershipStatusTile>{active_msg}</MembershipStatusTile>
                {user?.user?.drifflePlusMembershipDetails?.next_txn_date ? (
                  <p>
                    {plus_membership_next_billing_date_msg?.replace(
                      '[--DATE--]',
                      displayUniformDate(
                        user?.user?.drifflePlusMembershipDetails
                          ?.next_txn_date ?? ''
                      )
                    )}
                  </p>
                ) : user?.user?.drifflePlusMembershipDetails?.end_date ? (
                  <p>
                    {/* {plus_membership_ends_on_date_msg}{' '}
                    {displayUniformDate(
                      user?.user?.drifflePlusMembershipDetails?.end_date ?? ''
                    )} */}
                    {plus_membership_ends_on_date_msg?.replace(
                      '[--DATE--]',
                      displayUniformDate(
                        user?.user?.drifflePlusMembershipDetails?.end_date ?? ''
                      )
                    )}
                  </p>
                ) : null}
              </AlreadyPlusMemberCardFooter>
            </AlreadyPlusMemberCardContainer>
          </AlreadyPlusMemberCardWrapper>
        </>
      )}
    </>
  );
}

export default memo(AlreadyPlusMemberCard);
