import { useRouter } from 'next/router';
import { useCallback, useState } from 'react';
import usePlusMembershipFAQS from '../../../../hooks/localeConstants/usePlusMembershipFAQS';
import { saveEventV3 } from '../../../../utils/eventTracking';
import FAQAccordion from '../components/FAQAccordion';
import {
  FAQContainer,
  FAQSectionWrapper,
  FAQsSection,
} from '../LandingPageStyle';

function FAQSection() {
  const router = useRouter();
  const [open, setOpen] = useState<number | null>(null);

  const { plusFaqs } = usePlusMembershipFAQS();

  const handleToggle = useCallback(
    (index: number) => {
      saveEventV3({
        category: 'membership_faqs',
        label: 'dropdown',
        action: 'click',
        properties: '',
        value: [plusFaqs[index]?.title ?? ''],
        from: router,
      });
      setOpen((prev) => (prev === index ? null : index));
    },
    [plusFaqs, router]
  );

  return (
    <FAQSectionWrapper>
      <FAQContainer>
        <h2>Frequently asked questions</h2>
        <FAQsSection>
          {plusFaqs.map((cur, i) => (
            <FAQAccordion
              title={cur.title}
              description={cur.description}
              toggleOpen={() => handleToggle(i)}
              open={open === i}
              key={i}
            />
          ))}
        </FAQsSection>
      </FAQContainer>
    </FAQSectionWrapper>
  );
}

export default FAQSection;
