import { createContext, useContext, useEffect } from 'react';
import useSubscriptionSaving from '../hooks/useSubscriptionSaving';

interface MembershipPageContextInterface {
  savingAmount: number;
  loading: boolean;
}

const InitialMembershipPageContextState: MembershipPageContextInterface = {
  savingAmount: 0,
  loading: true,
};

export const MembershipPageContext =
  createContext<MembershipPageContextInterface>(
    InitialMembershipPageContextState
  );

export const useMembershipPageContext = () => useContext(MembershipPageContext);

export const MembershipPageContextProvider = ({ children }: any) => {
  const { fetchSubscriptionSaving, loading, savingAmount } =
    useSubscriptionSaving();

  useEffect(() => {
    fetchSubscriptionSaving();
  }, [fetchSubscriptionSaving]);

  return (
    <MembershipPageContext.Provider value={{ savingAmount, loading }}>
      {children}
    </MembershipPageContext.Provider>
  );
};
