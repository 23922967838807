import DOMPurify from 'isomorphic-dompurify';
import { useLocale } from '../../../../contexts/LocalizationContext';
import { useAppSelector } from '../../../../redux/hooks';
import { BackgroundIcon } from '../../../Icons/Icons';
import {
  LandingPageMainSectionContainer,
  LandingPageMainSectionTitle,
  LandingPageMainSectionTncText,
  RadialGlowBackground,
} from '../LandingPageStyle';
import AlreadyPlusMemberCard from '../components/AlreadyPlusMemberCard';
import JoinPlusSectionCardsMobile from '../components/JoinPlusSectionCardsMobile';

function LandingPageMainSectionMobile() {
  const { messages } = useLocale();
  const {
    plus_as_a_driffle_plus_member_msg,
    terms_of_service_msg,
    privacy_policy_msg,
  } = messages || {};

  const user = useAppSelector((state) => state.user);

  return (
    <LandingPageMainSectionContainer>
      <RadialGlowBackground />
      <LandingPageMainSectionTitle>
        <BackgroundIcon
          url="/logo/driffle-plus-dark-logo.svg"
          width="222px"
          height="40px"
          margin="0"
        />
      </LandingPageMainSectionTitle>
      {user?.subscription === 'active' ? (
        <AlreadyPlusMemberCard />
      ) : (
        <JoinPlusSectionCardsMobile />
      )}

      <LandingPageMainSectionTncText
        dangerouslySetInnerHTML={{
          __html: DOMPurify.sanitize(
            plus_as_a_driffle_plus_member_msg
              ?.replace(
                '[--TERMS_AND_CONDITIONS--]',
                `<a
                  href='/driffle-plus-terms-and-conditions'
                  target="_blank"
                  rel="noreferrer"
                >
                  ${terms_of_service_msg}
                </a>`
              )
              .replace(
                '[--PRIVACY_POLICY--]',
                `<a href='/privacy-policy' target="_blank" rel="noreferrer">
                  ${privacy_policy_msg}.
                </a>`
              ) ?? ''
          ),
        }}
      />
    </LandingPageMainSectionContainer>
  );
}

export default LandingPageMainSectionMobile;
