import { memo } from 'react';
import { useLocale } from '../../../../contexts/LocalizationContext';
import { membershipTypes } from '../../../../utils/membership';
import { BackgroundIcon } from '../../../Icons/Icons';
import Button from '../../../NewButton/Button';
import {
  BillingDetailsCaption,
  BillingDetailsContainer,
  BillingDetailsPrice,
  SubscriptionCardContainer,
  SubscriptionCardDivider,
  SubscriptionCardFeatureListItem,
  SubscriptionCardFeaturesList,
  SubscriptionCardStylishDivider,
  SubscriptionCardTitle,
  YouSaveBadge,
} from '../LandingPageStyle';

interface Props {
  title: string;
  features: string[];
  color: string;
  bgColor: string;
  membershipType: membershipTypes;
  billingDetails: {
    price: string;
    pricePerPeriod: string;
    durationText: string;
    ctaButton: string;
    ctaBtnText: string;
  };
  handleJoinNow: (membership: membershipTypes) => void;
  loading: boolean;
}

function SubscriptionCard({
  title,
  features,
  color,
  bgColor,
  membershipType,
  billingDetails,
  handleJoinNow,
  loading,
}: Props) {
  const { messages } = useLocale();
  const { plus_best_value_msg, most_popular_msg } = messages || {};

  return (
    <SubscriptionCardContainer backgroundColor={bgColor} borderColor={color}>
      {membershipType !== 'monthly' && (
        <YouSaveBadge membershipType={membershipType}>
          {
            {
              quarterly: most_popular_msg,
              yearly: plus_best_value_msg,
            }[membershipType]
          }
        </YouSaveBadge>
      )}
      <SubscriptionCardTitle>
        <h1>{title}</h1>
      </SubscriptionCardTitle>
      <SubscriptionCardStylishDivider membershipType={membershipType} />
      <SubscriptionCardFeaturesList>
        {features.map((feature, index) => (
          <SubscriptionCardFeatureListItem key={index}>
            <div>
              <BackgroundIcon
                url={`/icons/${
                  (membershipType === 'yearly' ? 'golden' : 'purple') +
                  '-tick-24'
                }.svg`}
                height="20px"
                width="20px"
                margin="0"
              />
            </div>
            <p>{feature}</p>
          </SubscriptionCardFeatureListItem>
        ))}
      </SubscriptionCardFeaturesList>
      <SubscriptionCardDivider color={color} />
      <BillingDetailsContainer>
        <BillingDetailsPrice>
          {billingDetails.price}
          <span>{billingDetails.pricePerPeriod}</span>
        </BillingDetailsPrice>
        <BillingDetailsCaption>
          {billingDetails.durationText}
        </BillingDetailsCaption>
      </BillingDetailsContainer>
      <Button
        buttonType="solid"
        buttonTheme="white"
        onClick={() => handleJoinNow(membershipType)}
        disabled={loading}
      >
        {loading ? 'Processing...' : billingDetails.ctaBtnText}
      </Button>
    </SubscriptionCardContainer>
  );
}

export default memo(SubscriptionCard);
