import { useRouter } from 'next/router';
import { memo, useCallback, useState } from 'react';
import { useLocale } from '../../../../contexts/LocalizationContext';
import { useAppSelector } from '../../../../redux/hooks';
import { saveEventV3 } from '../../../../utils/eventTracking';
import {
  buyMembership,
  getMembershipCheckoutDetails,
  membershipTypes,
} from '../../../../utils/membership';
import Button from '../../../NewButton/Button';
import {
  LandingPageMainSectionCaption,
  SubscriptionCardMobileWrapper,
} from '../LandingPageStyle';
import BenefitsOfPlus from './BenefitsOfPlus';
import SubscriptionCardMobile from './SubscriptionCardMobile';

function JoinPlusSectionCardsMobile() {
  const router = useRouter();

  const { messages } = useLocale();
  const {
    plus_join_our_membership_program_now_msg,
    plus_join_now_msg,
    plus_billed_monthly_cancel_anytime_msg,
    plus_billed_quaterly_cancel_anytime_msg,
    plus_billed_annually_cancel_anytime_msg,
    plus_per_month_msg,
    plus_per_quarter_msg,
    plus_per_year_msg,
    plus_3_months_msg,
    plus_number_month_msg,
    plus_number_year_msg,
  } = messages || {};

  const user = useAppSelector((state) => state.user);
  const [loading, setLoading] = useState(false);
  const [membershipType, setMembershipType] =
    useState<membershipTypes>('monthly');

  const handleSelectSubscriptionType = useCallback((value: membershipTypes) => {
    setMembershipType(value);
  }, []);

  const handleJoinNow = async (membership: membershipTypes) => {
    if (user.subscription === 'active') {
      router.push('/user/membership');
    } else {
      setLoading(true);
      try {
        saveEventV3({
          category: 'plan_options',
          label: 'join_now',
          action: 'click',
          properties: String(getMembershipCheckoutDetails(membership).title),
          value: [getMembershipCheckoutDetails(membership).price],
          from: router,
        });
        await buyMembership(membership);
        router.push('/plus/checkout');
      } catch (error) {
      } finally {
        setLoading(false);
      }
    }
  };

  return (
    <>
      <LandingPageMainSectionCaption>
        <p>{plus_join_our_membership_program_now_msg}</p>
      </LandingPageMainSectionCaption>
      <SubscriptionCardMobileWrapper>
        <SubscriptionCardMobile
          key={1}
          selected={membershipType === 'monthly'}
          handleSelect={handleSelectSubscriptionType}
          membershipType="monthly"
          title={
            plus_number_month_msg?.replace('[--NUMBER--]', '1') ?? '1 Month'
          }
          billingDetails={{
            ctaBtnText: plus_join_now_msg ?? 'Join now',
            ctaButton: 'https://driffle.com',
            durationText:
              plus_billed_monthly_cancel_anytime_msg ??
              'Billed monthly. Cancel anytime.',
            price: '€2.99',
            pricePerPeriod: plus_per_month_msg ?? 'per month',
          }}
        />

        <SubscriptionCardMobile
          key={2}
          selected={membershipType === 'quarterly'}
          handleSelect={handleSelectSubscriptionType}
          membershipType="quarterly"
          title={plus_3_months_msg ?? '3 Months'}
          billingDetails={{
            ctaBtnText: plus_join_now_msg ?? 'Join now',
            ctaButton: 'https://driffle.com',
            durationText:
              plus_billed_quaterly_cancel_anytime_msg ??
              'Billed quarterly. Cancel anytime.',
            price: '€7.99',
            pricePerPeriod: plus_per_quarter_msg ?? 'per quarter',
          }}
        />
        <SubscriptionCardMobile
          key={3}
          selected={membershipType === 'yearly'}
          handleSelect={handleSelectSubscriptionType}
          membershipType="yearly"
          title={plus_number_year_msg?.replace('[--NUMBER--]', '1') ?? '1 Year'}
          billingDetails={{
            ctaBtnText: plus_join_now_msg ?? 'Join now',
            ctaButton: 'https://driffle.com',
            durationText:
              plus_billed_annually_cancel_anytime_msg ??
              'Billed annually. Cancel anytime.',
            price: '€29.99',
            pricePerPeriod: plus_per_year_msg ?? 'per year',
          }}
        />
        <Button
          buttonType="solid"
          buttonTheme="white"
          width="100%"
          onClick={() => handleJoinNow(membershipType)}
          disabled={loading}
        >
          {loading ? 'Processing...' : plus_join_now_msg ?? 'Join now'}
        </Button>
      </SubscriptionCardMobileWrapper>
      <BenefitsOfPlus />
    </>
  );
}

export default memo(JoinPlusSectionCardsMobile);
