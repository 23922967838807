import Head from 'next/head';
import LandingPage from '../../components/Membership/LandingPage/LandingPage';
import SiteLayout from '../../Layout/SiteLayout';
import { ssrCommonMessages } from '../../localization/ssr/common';
import { ssrPlusMessages } from '../../localization/ssr/plus';
import { getUserInfoSSR } from '../../utils/cf';
import { getSelectedLocaleForSSR } from '../../utils/getSelectedLocaleForSSR';

function Page() {
  return (
    <>
      <Head>
        <title>Plus Membership - Driffle</title>
      </Head>
      <SiteLayout>
        <LandingPage />
      </SiteLayout>
    </>
  );
}

export default Page;

export async function getServerSideProps(context: any) {
  try {
    const localeData = await import(
      `../../localization/languages/${context?.locale ?? 'en'}.ts`
    );

    const ssrMessages = getSelectedLocaleForSSR(localeData.default, {
      ...ssrPlusMessages,
      ...ssrCommonMessages,
    });

    const userInfo = await getUserInfoSSR(context.req, context.res);

    if (userInfo.user.subscription === 'no') {
      return {
        notFound: true,
      };
    }

    return {
      props: {
        locale: context?.locale ?? 'en',
        localeData: ssrMessages,
        reduxInitialState: userInfo,
      },
    };
  } catch (err) {
    return {
      notFound: true,
    };
  }
}
