import { Skeleton } from '@mui/material';
import { memo } from 'react';
import {
  AlreadyPlusMemberCardContainer,
  AlreadyPlusMemberCardWrapper,
} from '../LandingPageStyle';

function AlreadyPlusMemberCardSkeleton() {
  return (
    <>
      <AlreadyPlusMemberCardWrapper>
        <Skeleton
          variant="rectangular"
          width="50%"
          height="24px"
          style={{ background: '#42264659', borderRadius: '8px' }}
        />

        <AlreadyPlusMemberCardContainer>
          <Skeleton
            variant="rectangular"
            width="100%"
            height="120px"
            style={{ background: '#42264659', borderRadius: '8px' }}
          />
        </AlreadyPlusMemberCardContainer>
      </AlreadyPlusMemberCardWrapper>
    </>
  );
}

export default memo(AlreadyPlusMemberCardSkeleton);
