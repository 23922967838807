import axios from 'axios';
import { useCallback, useState } from 'react';
import { apiConstants } from '../../../constants/apiConstants';
import { useAppSelector } from '../../../redux/hooks';

function useSubscriptionSaving() {
  const [loading, setLoading] = useState(true);
  const user = useAppSelector((state) => state.user);
  const [savingAmount, setSavingAmount] = useState<number>(0);

  const fetchSubscriptionSaving = useCallback(async () => {
    if (user.subscription === 'active' && !!user?.user) {
      try {
        setLoading(true);

        const { data } = await axios.get(
          apiConstants.server + '/private/customer/plus-savings',
          { withCredentials: true }
        );
        setSavingAmount(data.amountSaved);
      } catch (error) {
        setSavingAmount(0);
      } finally {
        setLoading(false);
      }
    }
  }, [user.subscription, user?.user]);

  return {
    loading,
    savingAmount,
    fetchSubscriptionSaving,
  };
}

export default useSubscriptionSaving;
