let optionsWithDateAndTime: Intl.DateTimeFormatOptions = {
  year: 'numeric',
  month: '2-digit',
  day: '2-digit',
  hour: '2-digit',
  minute: '2-digit',
};
let optionsWithDate: Intl.DateTimeFormatOptions = {
  year: 'numeric',
  month: '2-digit',
  day: '2-digit',
};
let optionsWithTime: Intl.DateTimeFormatOptions = {
  // year: 'numeric',
  // month: '2-digit',
  // day: '2-digit',
  hour: '2-digit',
  minute: '2-digit',
};

export const displayUniformDateWithTime = (value: string) => {
  return new Date(value)
    ?.toLocaleString('en-IN', optionsWithDateAndTime)
    ?.toLocaleUpperCase();
};

export const displayUniformDate = (value: string) => {
  return new Date(value)?.toLocaleDateString('en-IN', optionsWithDate);
};

export const displayUniformTime = (value: string) => {
  return new Date(value)
    ?.toLocaleTimeString('en-IN', optionsWithTime)
    ?.toLocaleUpperCase();
};
