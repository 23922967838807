import { memo } from 'react';
import { MaskIcon } from '../../../../components/Icons/Icons';
import {
  AccordionContainer,
  AccordionContent,
  AccordionHeader,
} from '../LandingPageStyle';

interface FAQAccordionProps {
  open?: boolean;
  title: string;
  description: string;
  toggleOpen: () => void;
}

const FAQAccordion = ({
  open,
  toggleOpen,
  title,
  description,
}: FAQAccordionProps) => {
  return (
    <AccordionContainer>
      <AccordionHeader onClick={toggleOpen}>
        <p>{title}</p>
        <div>
          <MaskIcon
            style={{
              cursor: 'pointer',
              transition: '0.3s',
              transform: open ? 'rotate(45deg)' : 'rotate(0deg)',
            }}
            url="/icons/add.svg"
            width="24px"
            height="24px"
            margin="0"
          />
        </div>
      </AccordionHeader>
      <AccordionContent
        style={{
          height: open ? 'auto' : '0',
          transition: '0.3s',
          overflow: 'hidden',
        }}
        dangerouslySetInnerHTML={{ __html: description }}
      ></AccordionContent>
    </AccordionContainer>
  );
};

export default memo(FAQAccordion);
