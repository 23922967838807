import { GWMobileWrapper, GWWebWrapper } from '../../../styles/window.style';
import { MembershipPageContextProvider } from '../context/MembershipLandingPageContext';
import { Wrapper } from './LandingPageStyle';
import FAQSection from './sections/FAQSection';
import LandingPageMainSection from './sections/LandingPageMainSection';
import LandingPageMainSectionMobile from './sections/LandingPageMainSectionMobile';
import WhatDoYouGetSection from './sections/WhatDoYouGetSection';

function LandingPage() {
  return (
    <MembershipPageContextProvider>
      <Wrapper>
        <GWWebWrapper>
          <LandingPageMainSection />
        </GWWebWrapper>
        <GWMobileWrapper>
          <LandingPageMainSectionMobile />
        </GWMobileWrapper>

        <WhatDoYouGetSection />
        {/* <GWWebWrapper>
          <GetExtraDiscountSection />
        </GWWebWrapper> */}
        <FAQSection />
      </Wrapper>
    </MembershipPageContextProvider>
  );
}

export default LandingPage;
