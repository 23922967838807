import { memo } from 'react';
import { membershipTypes } from '../../../../utils/membership';
import {
  MembershipSelectInputRadio,
  SubscriptionCardMobileBodyText,
  SubscriptionCardMobileContainer,
  SubscriptionCardMobileHeader,
  SubscriptionCardMobileHeaderTitle,
  SubscriptionCardMobileHeaderValue,
} from '../LandingPageStyle';

interface Props {
  selected: boolean;
  handleSelect: (value: membershipTypes) => void;
  title: string;
  billingDetails: {
    price: string;
    pricePerPeriod: string;
    durationText: string;
    ctaButton: string;
    ctaBtnText: string;
  };
  membershipType: membershipTypes;
}

function SubscriptionCardMobile({
  selected,
  handleSelect,
  membershipType,
  billingDetails,
  title,
}: Props) {
  return (
    <SubscriptionCardMobileContainer
      selected={selected}
      onClick={() => handleSelect(membershipType)}
    >
      <SubscriptionCardMobileHeader>
        <SubscriptionCardMobileHeaderTitle>
          <MembershipSelectInputRadio
            type="radio"
            checked={selected}
            onChange={() => handleSelect(membershipType)}
            fillColor={selected ? '#9D60EA' : null}
          />
          {title}
        </SubscriptionCardMobileHeaderTitle>
        <SubscriptionCardMobileHeaderValue>
          <p>{billingDetails.price}</p>
          <span>/{billingDetails.pricePerPeriod}</span>
        </SubscriptionCardMobileHeaderValue>
      </SubscriptionCardMobileHeader>
      <SubscriptionCardMobileBodyText>
        {billingDetails.durationText}
      </SubscriptionCardMobileBodyText>
    </SubscriptionCardMobileContainer>
  );
}

export default memo(SubscriptionCardMobile);
