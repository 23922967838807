import DOMPurify from 'isomorphic-dompurify';
import Image from 'next/image';
import { memo } from 'react';
import { useLocale } from '../../../../contexts/LocalizationContext';
import {
  WhatDoYouGetSectionBody,
  WhatDoYouGetSectionCard,
  WhatDoYouGetSectionCardContent,
  WhatDoYouGetSectionTitle,
  WhatDoYouGetSectionWrapper,
} from '../LandingPageStyle';

function WhatDoYouGetSection() {
  const { messages } = useLocale();
  const {
    plus_what_do_you_get_with_msg,
    plus_extra_discount_on_driffle_msg,
    plus_buy_keys_cheaper_than_ever_msg,
    plus_exclusive_sales_events_msg,
    plus_special_promos_early_access_msg,
    plus_priority_preorder_delivery_msg,
    plus_get_keys_delivered_fast_msg,
    plus_priority_assistance_msg,
    plus_super_fast_assistance_msg,
  } = messages || {};

  return (
    <WhatDoYouGetSectionWrapper>
      <WhatDoYouGetSectionTitle
        dangerouslySetInnerHTML={{
          __html: DOMPurify.sanitize(
            plus_what_do_you_get_with_msg?.replace(
              '[--PLUS--]',
              `
                 <img
                  src="/logo/plus-logo.svg"
                  width="82px"
                  height="44px"
                  style="margin: 0 4px 0 10px;"
                  alt="Plus Logo"
                />
              `
            ) ?? ''
          ),
        }}
      />
      <WhatDoYouGetSectionBody>
        <WhatDoYouGetSectionCard>
          <Image
            src="/icons/plus-extra-discount.svg"
            alt="plus-illustration"
            layout="fixed"
            width="94px"
            height="94px"
            unoptimized
          />
          <WhatDoYouGetSectionCardContent>
            <h3>{plus_extra_discount_on_driffle_msg}</h3>
            <p>{plus_buy_keys_cheaper_than_ever_msg}</p>
          </WhatDoYouGetSectionCardContent>
        </WhatDoYouGetSectionCard>
        <WhatDoYouGetSectionCard>
          <Image
            src="/icons/plus-exclusive-sales.svg"
            alt="plus-illustration"
            layout="fixed"
            width="94px"
            height="94px"
            unoptimized
          />
          <WhatDoYouGetSectionCardContent>
            <h3>{plus_exclusive_sales_events_msg}</h3>
            <p>{plus_special_promos_early_access_msg}</p>
          </WhatDoYouGetSectionCardContent>
        </WhatDoYouGetSectionCard>
        <WhatDoYouGetSectionCard>
          <Image
            src="/icons/plus-priority-pre-order.svg"
            alt="plus-illustration"
            layout="fixed"
            width="94px"
            height="94px"
            unoptimized
          />
          <WhatDoYouGetSectionCardContent>
            <h3>{plus_priority_preorder_delivery_msg}</h3>
            <p>{plus_get_keys_delivered_fast_msg}</p>
          </WhatDoYouGetSectionCardContent>
        </WhatDoYouGetSectionCard>

        <WhatDoYouGetSectionCard>
          <Image
            src="/icons/plus-priority-assistance.svg"
            alt="plus-illustration"
            layout="fixed"
            width="94px"
            height="94px"
            unoptimized
          />
          <WhatDoYouGetSectionCardContent>
            <h3>{plus_priority_assistance_msg}</h3>
            <p>{plus_super_fast_assistance_msg}</p>
          </WhatDoYouGetSectionCardContent>
        </WhatDoYouGetSectionCard>
      </WhatDoYouGetSectionBody>
    </WhatDoYouGetSectionWrapper>
  );
}

export default memo(WhatDoYouGetSection);
