import { useMemo } from 'react';

interface PlusFAQ {
  title: string;
  description: string;
}

function usePlusMembershipFAQS() {
  const plusFaqs: PlusFAQ[] = useMemo(() => {
    return [
      {
        title: 'How can I join Driffle Plus?',
        description: `
                            <p>
                              There are three simple ways to join the club and activate your membership:
                            </p>
                            <ul>
                              <li>
                                <span>Via the Driffle Plus main page:</span> Select a recurring membership plan - monthly, quarterly, or annually.
                              </li>
                              <li>
                                <span>When making a purchase:</span> Select the offer with the Plus discount on Driffle.com. The membership will then be added to the cart along with the product and its discounted price.
                              </li>
                              <li>
                                <span>In your shopping cart:</span> Enable the toggle button in the Driffle Plus box before finalizing your order and proceeding to payment. This will update the product's price in the cart.
                              </li>
                            </ul>
                          `,
      },
      {
        title: 'What benefits do I get with a Driffle Plus membership?',
        description: `
                            <p>With Driffle Plus, you get: </p>
                            <ul>
                              <li>
                                <span>Bonus discount: </span> Up to 10% on games, gift cards, DLCs, softwares, subscriptions, and game points on every purchase.
                              </li>
                                <li>
                                <span>Exclusive access: </span> To sales and events.
                              </li>
                                <li>
                                <span>Priority support: </span> For all your queries and issues.
                              </li>
                                <li>
                                <span>First access: </span> Priority for pre-orders!
                              </li>
                            </ul>
                            `,
      },
      {
        title: 'Are there any exclusive events for Driffle Plus members?',
        description: `
                            <p>Yes, Driffle Plus members get access to exclusive sales, events, and early access to special promotions. Keep an eye on your email and the Driffle Plus tab for upcoming events and offers.</p>
                          `,
      },

      {
        title:
          'Why is my Driffle Plus discount not applying when I use a promo code?',
        description: `
                            <p> The Driffle Plus discount cannot be combined with any other promotional codes or offers. When using a promo code at checkout, the Plus discount will not be applied. To maximize your savings, you can choose to either use your Driffle Plus discount or apply the promo code, depending on which offers the better deal. </p>
                          `,
      },
      {
        title:
          'Is there a limit on how many product keys I can purchase each month with Driffle Plus?',
        description: `
                            <p>
                            Yes, as a Driffle Plus member, you can purchase a maximum of 3 keys per product each month. This limit is set to ensure fair access to popular items for all our users. If you reach this limit, you'll be able to purchase the same product again in the following month.
                            </p>
                          `,
      },

      {
        title: 'Will my Driffle Plus membership renew automatically?',
        description: `
                            <p>Yes, your Driffle Plus membership will renew automatically at the beginning of each billing cycle. The billing cycle depends on the plan you have chosen(monthly, quarterly, annually)
                            </p>
                            `,
      },
      {
        title: 'How do I manage my Driffle Plus subscription?',
        description: `
                            <p>You can manage your subscription by: </p>
                            <ul>  
                              <li>
                                Logging into your Driffle.com account.
                              </li>
                              <li>
                                 Navigate to the Driffle Plus tab in the profile section.
                              <li>
                                Here, you can view your subscription details, check your billing history, or cancel your membership.
                              </li>
                            </ul>  
                          `,
      },
      {
        title:
          'What happens to my discounts and benefits if I cancel Driffle Plus?',
        description: `
                            <p> If you cancel your Driffle Plus membership, you will continue to receive discounts and benefits until the end of your current billing cycle(monthly, quarterly, annually). After that, your membership will be inactive, and you will no longer have access to Driffle Plus benefits. </p>
                          `,
      },
      {
        title: 'As a seller, can I become a Driffle Plus member?',
        description: `
                            <p> No, you can&apos;t. Driffle Plus is a program designed for buyers only. </p>
                          `,
      },
    ];
  }, []);

  return {
    plusFaqs,
  };
}

export default usePlusMembershipFAQS;
